import { useContext, useState } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { EventContext, AnalyticsContext } from "context";
import { PlayIcon, Modal, Media16By9, MediaVideo } from "Components";
import { fonts } from "theme/fonts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const WinningArtistCard = () => {
  const { event } = useContext(EventContext);
  const { logClick, logVideoAction } = useContext(AnalyticsContext);

  const [open, setOpen] = useState(false);
  const [video, setVideo] = useState(null);

  const setModalVisible = (bool, link) => {
    setVideo(link);
    setOpen(bool);
    logVideoAction({
      bool,
      videoLink: link,
      videoTitle: "Winning Artist Video",
    });
  };

  const socialIconHandler = (link) => {
    logClick({
      label: "Social Icon",
      url: link,
    });
  };

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event?.meta?.storageDirectory;
  const { artists, winner } = event.voting.localArtists;
  const winningArtist = artists.find((artist) => artist.winner);

  const {
    artist: artistName,
    // bio,
    bioVideo,
    // image,
    social,
    // song,
    // winner: contestWinner,
  } = winningArtist;

  const {
    artistImage,
    heading,
    // body, registerBtn,
    tag,
    teaser,
  } = winner;
  let desktopImage = `${api}/o/${storageDirectory}%2F${artistImage.desktop}?alt=media`;
  let mobileImage = `${api}/o/${storageDirectory}%2F${artistImage.mobile}?alt=media`;

  return (
    <>
      {winningArtist && (
        <>
          <Wrapper>
            <ArtistImage mobileImage={mobileImage} desktopImage={desktopImage}>
              <Overlay>
                <OverlayContainer>
                  <OverlayHeading>{heading[1]}</OverlayHeading>
                  <PlayIcon onClick={() => setModalVisible(true, bioVideo)} />
                </OverlayContainer>
              </Overlay>
            </ArtistImage>
            <Content>
              <Main>
                <Tag>{tag}</Tag>
                <Heading>{ReactHtmlParser(artistName)}</Heading>
                <Body>{ReactHtmlParser(teaser)}</Body>
              </Main>
              <Socials>
                <SocialIconList>
                  {social.map((socialIcon, idx) => {
                    if (!socialIcon.link) return null;
                    return (
                      <li key={idx}>
                        <a
                          href={socialIcon.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => socialIconHandler(socialIcon.link)}
                        >
                          <FontAwesomeIcon icon={["fab", socialIcon.icon]} />
                        </a>
                      </li>
                    );
                  })}
                </SocialIconList>
              </Socials>
            </Content>
          </Wrapper>

          <Modal open={open} setOpen={setModalVisible}>
            <Media16By9>
              <MediaVideo
                src={video}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              />
            </Media16By9>
          </Modal>
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 -15px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    margin: 0;
  }
`;

const ArtistImage = styled.div`
  height: 70vw;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.mobileImage});
  position: relative;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    background-image: url(${(props) => props.desktopImage});
    height: 455px;
    align-items: start;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  display: flex;
  align-items: end;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    align-items: start;
    padding: 50px 35px;
  }
`;

const OverlayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex-direction: column;
    align-items: start;
    width: 35%;
  }
`;

const OverlayHeading = styled.h2`
  text-transform: uppercase;
  width: 75%;
  margin: 0 15px 0 0;
  font-size: clamp(26px, 5vw, 40px);
  line-height: 1.25;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    display: block;
    width: unset;
    margin: 0 0 8px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #1336bf;
  padding: 35px 25px;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    flex: 1;
    padding: 35px;
    align-items: center;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: auto;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    width: 52%;
  }
`;

const Tag = styled.small`
  text-transform: uppercase;
  margin-bottom: 8px;
  font-family: ${fonts.header};
`;

const Socials = styled.div`
  display: flex;
  align-items: end;
  padding: 15px 15px 23px;
  background-color: #1437bf;
`;

const SocialIconList = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 28px;

  li {
    margin-left: 16px;
  }

  a {
    &:hover {
      opacity: 0.5;
    }
  }
`;

const Heading = styled.h3`
  text-transform: uppercase;
  font-size: 24px;
  margin-bottom: 0px;
`;

const Body = styled.p`
  margin-top: 12px;
`;
