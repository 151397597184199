import { useContext } from "react";
import styled from "styled-components";
import ReactHtmlParser from "react-html-parser";
import { TimeContext, EventContext, AnalyticsContext } from "context";
import { MaxWidthContainer } from "./MaxWidthContainer";

export const Footer = ({
  showFooter,
  registration,
  registered,
  className,
  voting,
  postCampaign,
}) => {
  const { time } = useContext(TimeContext);
  const { event } = useContext(EventContext);
  const { logClick } = useContext(AnalyticsContext);

  const api = process.env.REACT_APP_FIRE_BASE_STORAGE_API;
  const storageDirectory = event?.meta?.storageDirectory;

  const {
    // brand: brandLogo,
    // event: eventLogo,
    secondary: secondaryLogo,
  } = event.logos;

  let secondaryLogoImg = `${api}/o/${storageDirectory}%2F${secondaryLogo?.image}?alt=media`;

  const footerMenu = false;
  const showLogo = true;

  const {
    heading,
    subHeading,
    headingRegistered,
    subHeadingRegistered,
    headingPostCampaign,
    maxPolicy,
    brandDisclaimer,
    sweepsDisclaimer,
    grandPrizeDisclaimer,
  } = event.footer;

  const linkClickHandler = (url, label, category) => {
    logClick({ label, url }, category);
  };

  const footerMenuClickHandler = (link) => {
    logClick({
      label: "Footer Menu Link",
      url: link,
    });
  };

  return (
    <>
      {showFooter && (
        <FooterWrapper as="footer" className={className}>
          <FooterContainer>
            <MaxPolicy>{ReactHtmlParser(maxPolicy)}</MaxPolicy>
            <MaxPolicy>
              <CookieButton
                onClick={() => {
                  if (window.Cookiebot) {
                    window.Cookiebot.renew();
                  }
                }}
              >
                Click Here to set Cookie Preferences.
              </CookieButton>
            </MaxPolicy>
          </FooterContainer>
        </FooterWrapper>
      )}
    </>
  );
};

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-size: 10px;
  padding: 0 15px;
  z-index: 1;
  background-color: #fff;

  @media ${(props) => props.theme.maxMedia.medium} {
    padding: 0 50px;
  }
`;

const FooterContainer = styled(MaxWidthContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 15px 0;
  position: relative;
  color: white;

  @media only screen and ${(props) => props.theme.maxMedia.medium} {
    padding: 25px 0;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const MaxPolicy = styled.p`
  text-align: center;
  font-size: 16px;
  color: black;
  margin-top: 0px;
  font-family: "Speedee_RegCd";

  a {
    color: black;
  }

  @media (max-width: 950px) {
    font-size: 16px;
  }
`;

const BrandDisclaimer = styled.div`
  text-align: center;
  margin-top: 6em;
  font-size: 0.6rem;

  @media (max-width: 950px) {
    font-size: 0.6rem;
    margin-bottom: -10px;
  }
`;
export const CookieButton = styled.button`
  border: none;
  background-color: transparent;
  color: #000;
  display: inline;
  font-size: 16px;
  font-family: "Speedee_RegCd";
  font-weight: 300;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
`;
